import {
  SignOutButton,
  SignInButton,
  SignedIn,
  SignedOut,
} from "@clerk/clerk-react";
import { useUser } from "@clerk/clerk-react";
import Classes from "./Classes";
import { useEffect, useMemo } from "react";

export default function Home({ students, loading, setStudents, setLoading }) {
  const { isSignedIn, user } = useUser();

  console.log(user?.publicMetadata?.instituteId);
  
  useEffect(() => {
      
    

    // if(students?.length <= 0){
    //   const fetchData = async () => {
    //     try {
    //       console.log('Effect is running');
    //       setLoading(true);
    //       fetch('https://api.airtable.com/v0/app0I6JwR9ueBJU7A/Students?fields%5B%5D=Student&fields%5B%5D=StudentPic&fields%5B%5D=CourseName&fields%5B%5D=SubName&fields%5B%5D=ClassName&view=viwweYOkB7Voate8P',  {
    //           headers: {
    //             Authorization: 'Bearer patjCTstM7h4Wv3jI.7f3ad91670687575f594589a3acd70f15985b60829548bef01453c3df96ef3b9',
    //           }})
    //       .then((res) => res.json())
    //       .then((data) => {
    //         setLoading(false);
    //         const groupedData = data.records.reduce((acc, obj) => {

    //         let subName = "Unassigned"; // Assuming SubName is always an array with one element

    //         if(obj.fields?.ClassName && obj.fields?.ClassName[0]){
    //           subName = obj.fields?.ClassName[0];
    //         }

    //           if (!acc[subName]) {
    //               acc[subName] = [];
    //           }
    //           acc[subName].push(obj);
    //           return acc;
    //           }, {});
           
    //           setStudents(groupedData);
            
    //         console.log(groupedData);
    //       })
    //       .catch((error) => {
    //         console.log(error);
    //       });
    //     } catch (error) {
    //       console.error('Error fetching data:', error);
    //     }
    // };
    // if(students?.length <= 0){
    //   fetchData();
    // }
    
    // }
        

    // Since we only want this effect to run once, we use an empty dependency array
  }, []);

  const fetchStudents = () => {
    if(students?.length <= 0){
      const fetchData = async () => {
        try {
          console.log('Effect is running');
          setLoading(true);
          fetch('https://api.airtable.com/v0/app0I6JwR9ueBJU7A/Students?fields%5B%5D=Student&fields%5B%5D=StudentPic&fields%5B%5D=CourseName&fields%5B%5D=SubName&fields%5B%5D=ClassName&view=viwweYOkB7Voate8P',  {
              headers: {
                Authorization: 'Bearer patjCTstM7h4Wv3jI.7f3ad91670687575f594589a3acd70f15985b60829548bef01453c3df96ef3b9',
              }})
          .then((res) => res.json())
          .then((data) => {
            setLoading(false);
            const groupedData = data.records.reduce((acc, obj) => {

            let subName = "Unassigned"; // Assuming SubName is always an array with one element

            if(obj.fields?.ClassName && obj.fields?.ClassName[0]){
              subName = obj.fields?.ClassName[0];
            }

              if (!acc[subName]) {
                  acc[subName] = [];
              }
              acc[subName].push(obj);
              return acc;
              }, {});
           
              setStudents(groupedData);
            
            console.log(groupedData);
          })
          .catch((error) => {
            console.log(error);
          });
        } catch (error) {
          console.error('Error fetching data:', error);
        }
    };
    if(students?.length <= 0){
      fetchData();
    }
    
    }
  }

  return (<div class="container mx-auto px-4 py-20">

    <SignedOut>
    <div className="card card-side bg-base-100 shadow-xl">
      <figure><img src="https://img.freepik.com/premium-photo/rocket-white-background-3d-rendering-illustration_276199-119.jpg" alt="Movie"/></figure>
      <div className="card-body">
        <h2 className="card-title">Attendance 🚀</h2>
        <p>Log in to complete attendance.</p>
        <div className="card-actions justify-end">
            <SignInButton className="btn"/>
        </div>
      </div>
    </div>
    </SignedOut>
    <SignedIn>
        {/* <SignOutButton className="btn"/> */}
        { students && students.length <= 0 && <a className="btn text-xl" onClick={fetchStudents}>Show classes 🚀</a>}
        {loading && <span className="loading loading-spinner loading-lg"></span>}
        {isSignedIn ? (<Classes students={students}/>
        ) : <span className="loading loading-spinner loading-lg"></span> }
      </SignedIn>
     </div>
  );
}
