import React from 'react';
import { useNavigate } from 'react-router-dom';

export const Classes = ({students}) => {

  const navigate = useNavigate();

  var studButtons = null;  

  if(students){
    console.log(students);
    studButtons = Object.keys(students).map((key) => (
        <div key={key} onClick={() => navigate(`/classes/${key}`)} className="card m-4 w-80 shadow">
            <div className="card-body">
                <h2 className="card-title">{key}</h2>
                <p>Total students: {students[key].length}</p>
            </div>
        </div>
    ));
  }

  return (
    <div>
       {studButtons}
    </div>
  );
};

export default Classes;
