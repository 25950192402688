import './index.css';
import './App.css';
import Example from './Example';
import { ClerkProvider, SignOutButton } from "@clerk/clerk-react";
import React, {useState} from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Home from './Home';
import Navbar from './Navbar';
const publishableKey = 'pk_live_Y2xlcmsuZW5nbGlmeS5pbyQ';

function App() {
   
  const [loading, setLoading] = useState(false);

  const [students, setStudents] = useState([]);

  return (
    <ClerkProvider publishableKey={publishableKey}>
         <Navbar signout={<SignOutButton className="btn"/>}/>
         <Router>
              <Routes>
                  <Route path='/' element={<Home students={students} loading={loading} setStudents={setStudents} setLoading={setLoading}/>} />
                  <Route path="/classes">
                      <Route path=":classId" element={<Example students={students}/>}/>
                  </Route>
                  <Route path="/success" element={<>
                    <div className="hero min-h-screen bg-base-200">
                    <div className="hero-content flex-col lg:flex-row">
                        {/* <img src="https://media.istockphoto.com/id/1388186992/vector/yellow-happy-face-vector-symbol-icon.jpg?s=612x612&w=0&k=20&c=GZBWkt0ZLxLHkxU58BhRqkk41xC0LySjXa0N65xMDlw=" alt='spider-man' className="max-w-sm rounded-lg shadow-2xl" /> */}
                        <div>
                        <h1 className="text-5xl font-bold">Attendance done!</h1>
                        <p className="py-6">Have a wonderful day 🌸 !</p>
                        <SignOutButton className="btn btn-primary"/>
                        </div>
                    </div>
                    </div>
                  </>} />
              </Routes>              
          </Router>
    </ClerkProvider>
  );
}

export default App;
