import { useNavigate, useParams } from "react-router-dom";

import { useUser } from "@clerk/clerk-react";
import Layover from "./Layover";

export default function Example({students}) {

  const { isSignedIn, user } = useUser();

  const navigate = useNavigate();

  const { classId } = useParams();

  const mappedArray = students[classId].map(item => {
  
  const date = new Date();

  const formattedDate = date.toLocaleString('en-US', {
        month: 'short',
        day: 'numeric',
        year: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
        hour12: true
  });
  
  const newStudent = {attendance: 'absent', student: item.fields.Student, dateTime: formattedDate};
     
  if(item.fields.StudentPic && item.fields.StudentPic.length > 0 && item.fields?.StudentPic[0].url){
        newStudent.url = item.fields?.StudentPic[0].url;
      }

      return newStudent;
  });

    const handleAttendance = (event, studentId) => {
        console.log(`Marking for student with ID: ${studentId}`);
        console.log(event.target.checked);
        //attendanceArray.findIndex(item => item.student === studentId)

        const attendanceIndex = mappedArray.findIndex(item => item.student === studentId);
        

        //update the student with institute id and date time stamp 

        // console.log(mappedArray)
        mappedArray[attendanceIndex].attendance = event.target.checked ? 'present' : 'absent';

        const date = new Date();

        const formattedDate = date.toLocaleString('en-US', {
              month: 'short',
              day: 'numeric',
              year: 'numeric',
              hour: 'numeric',
              minute: 'numeric',
              hour12: true
        });

        mappedArray[attendanceIndex].dateTime = formattedDate;

        //after that submit the backend api with attendance
       
        console.log(mappedArray);
      };
    
    const handleSubmit = async () => {        
        try {
          if(isSignedIn && user?.publicMetadata?.instituteId){

            //show modal
            document.getElementById('loadingModal').showModal();

            const response = await fetch('https://attendance-marker-api.onrender.com/mark-attendance', {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json'
              },
              body: JSON.stringify({
                "instituteId": user?.publicMetadata?.instituteId,
                "values": mappedArray
              })
            });

            if(response.ok){
              document.getElementById('loadingModal').close();
              navigate("/success");
              
            }
      
            if (!response.ok) {
              throw new Error('Network response was not ok');
            }
          }

        } catch (error) {
          // Handle error
            document.getElementById('loadingModal').close();
            document.getElementById('errorModal').showModal();
         
        }
      };
      
    
    return (
      <div class="container mx-auto px-4 py-20">
        <ul  className="divide-y divide-gray-100">
        <h2>{classId}</h2>
        {mappedArray.map((person) => (
          <li key={person.student} className="flex flex-col gap-y-2 py-4 sm:flex-row sm:items-center sm:gap-x-6">
            <div className="flex items-center gap-x-4">
              <img className="h-20 w-20 rounded-full bg-gray-50" src={person?.url} alt="" />
              <input type="checkbox"  onChange={(event) => handleAttendance(event, person.student)} className="checkbox checkbox-success" />
              <p className="text-sm font-semibold leading-6 text-gray-900">{person.student}</p>
            </div>
          </li>
        ))}
        <button className="btn btn-success" onClick={handleSubmit}>Submit</button>
        <Layover id={"loadingModal"}/>
        <Layover id={"errorModal"} content={"Something happened try to submit again"}/>
      </ul>
      </div>
    )
  }