import { useUser } from "@clerk/clerk-react"


export default function Navbar({signout}) {

    const { isSignedIn, user } = useUser();
    return (
        <>
            <div className="navbar bg-base-300">
               
                <div className="navbar-center">
                    <a className="btn text-xl">Attendance Taker 🚀</a>
                </div>
                <div className="navbar-end">
                    {isSignedIn && signout}
                </div>
                </div>
        </>
    )
} 