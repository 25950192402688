export default function Layover ({id, content}) {

    return (
        <>
        <dialog id={id} className="modal">
        <div className="modal-box">
            <h3 className="font-bold text-lg">Hello!</h3>
            {/* <p className="py-4">Press ESC key or click the button below to close</p> */}
            {!content && <span className="loading loading-spinner loading-lg"></span>}
            {content}
            {content && 
            <div className="modal-action">
            <form method="dialog">
                <button className="btn">Close</button>
            </form>
            </div>}
            
        </div>
        </dialog>
        </>
    )

}